import { useState, useEffect, useRef, createRef } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { fetchCockpitData } from './utils/cockpit';
import Intro from './views/Intro';
import Information from './views/Information';
import Play from './views/Play';
import Win from './views/Win';
import { Link } from 'react-router-dom';
import BumperCars from './components/BumperCars';
import Audio from './components/Audio';
import Header from './components/Header';
import Background from './components/Background';
import { AnimatePresence } from 'framer-motion';
import * as _ from 'underscore';

const Content = (props) => {

  const [windowDimensions, setWindowDimensions] = useState({
    windowWidth: window.innerWidth,
    windowHeight: window.innerHeight
  });

  const introAudio = useRef();
  const tickingAudio = useRef();
  const matterCars = useRef();
  const [carAudioRefs, setCarAudioRefs] = useState([]);

  const [isTouchScreen, setIsTouchScreen] = useState(null);
  const [tickingIsActive, setTickingIsActive] = useState(true);
  const [bumperCarsData, setBumperCarsData] = useState({});
  const [bumperCarNamesData, setBumperCarNamesData] = useState({});
  const [siteOptionsData, setSiteOptionsData] = useState({});
  const [introData, setIntroData] = useState({});
  const [informationData, setInformationData] = useState({});
  const [instructionsData, setInstructionsData] = useState({});
  const [winData, setWinData] = useState({});
  const [introIsComplete, setIntroIsComplete] = useState(false);
  const [activeBpm, setActiveBpm] = useState(-1);
  const [selectedCar, setSelectedCar] = useState({});
  const [isInitialized, setIsInitialized] = useState(false);
  const [initialWindowMaxSide] = useState(Math.max(window.innerWidth, window.innerHeight));
  const [menuIsActive, setMenuIsActive] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsInitialized(true);
    }, 1200);
  }, []);

  useEffect(() => {

    fetchCockpitData('bumperCars', setBumperCarsData, true);
    fetchCockpitData('bumperCarNames', setBumperCarNamesData, true);
    fetchCockpitData('siteOptions', setSiteOptionsData, false);
    fetchCockpitData('intro', setIntroData, false);
    fetchCockpitData('about', setInformationData, false);
    fetchCockpitData('instructions', setInstructionsData, false);
    fetchCockpitData('win', setWinData, false);

    const handleResize = () => {
      setWindowDimensions({
        windowWidth: window.innerWidth,
        windowHeight: window.innerHeight
      });
    }

    const handleResizeThrottled = _.throttle(handleResize, 200);

    setWindowDimensions({
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight
    });
    if (isTouchScreen === true) {
      setIsTouchScreen(true);
    }
    window.addEventListener('resize', handleResizeThrottled);

    return () => {
      window.removeEventListener('resize', handleResizeThrottled);
    }
  }, [isTouchScreen]);

  useEffect(() => {
    const newCarAudioRefs = [];
    if (bumperCarsData.entries) {
      for (let i = 0; i < bumperCarsData.entries.length; i++) {
        const ref = createRef();
        newCarAudioRefs.push(ref);
      }
    }
    setCarAudioRefs(newCarAudioRefs);
  }, [bumperCarsData.entries]);

  useEffect(() => {

  }, [carAudioRefs]);

  return (
    <div id="main">
      <div
        className={`App${isInitialized === true ? ' initialised' : ''}${menuIsActive === true ? ' menu-is-active' : ''}`}
        style={{
          width: windowDimensions.windowWidth + 'px',
          height: windowDimensions.windowHeight + 'px'
        }}
      >
        {
          props.location.pathname !== '/play' && windowDimensions.windowWidth < 1200 && windowDimensions.windowWidth >= 500 &&
          <Link className="button--large link--play--mobile link--play" to="/play">Play</Link>
        }
        <Background
          {...props}
          pathname={props.location.pathname}
          siteOptionsData={siteOptionsData}
          windowDimensions={windowDimensions}
          bumperCarsData={bumperCarsData}
        />
        <Header
          pathname={props.location.pathname}
          windowDimensions={windowDimensions}
          siteOptionsData={siteOptionsData}
          activeBpm={activeBpm}
          introAudio={introAudio}
          carAudioRefs={carAudioRefs}
          menuIsActive={menuIsActive}
          setMenuIsActive={setMenuIsActive}
          setTickingIsActive={setTickingIsActive}
        />
        <div className="content"
          style={{
            width: '100%',
            height: '100%',
            position: 'relative',
            zIndex: 6
          }}
        >
        <AnimatePresence exitBeforeEnter={true}>
          <Switch>
              <Route
                key="intro"
                exact
                path="/"
                render={(props) => (
                  <Intro
                    {...props}
                    windowDimensions={windowDimensions}
                    pathname={props.location.pathname}
                    introIsComplete={introIsComplete}
                    setIntroIsComplete={setIntroIsComplete}
                    introData={introData}
                    introAudio={introAudio}
                    tickingAudio={tickingAudio}
                    tickingIsActive={tickingIsActive}
                    setTickingIsActive={setTickingIsActive}
                    matterCars={matterCars}
                  />
                )}
              />
              <Route
                key="win"
                exact
                path="/win"
                render={(props) => (
                  <Win
                    {...props}
                    windowDimensions={windowDimensions}
                    pathname={props.location.pathname}
                    bumperCarsData={bumperCarsData}
                    bumperCarNamesData={bumperCarNamesData}
                    selectedCar={selectedCar}
                    setSelectedCar={setSelectedCar}
                    matterCars={matterCars}
                    winData={winData}
                  />
                  // </AnimatePresence>
                )}
              />
              <Route
                key="play"
                exact
                path="/play"
                render={(props) => (
                  <Play
                    {...props}
                    instructionsData={instructionsData}
                    windowDimensions={windowDimensions}
                    pathname={props.location.pathname}
                    introAudio={introAudio}
                    matterCars={matterCars}
                  />
                )}
              />
              <Route
                key="information"
                exact
                path="/information"
                render={(props) => (
                  <Information
                    {...props}
                    informationData={informationData}
                    windowDimensions={windowDimensions}
                    pathname={props.location.pathname}
                  />
                )}
              />
            </Switch>
          </AnimatePresence>
          {
            bumperCarsData.entries &&
            carAudioRefs.length === bumperCarsData.entries.length &&
            <BumperCars
              {...props}
              windowDimensions={windowDimensions}
              bumperCarsData={bumperCarsData}
              carAudioRefs={carAudioRefs}
              pathname={props.location.pathname}
              selectedCar={selectedCar}
              setSelectedCar={setSelectedCar}
              initialWindowMaxSide={initialWindowMaxSide}
              introAudio={introAudio}
              matterCars={matterCars}
            />
          }
        </div>
      </div>
      <Audio
        {...props}
        introData={introData}
        bumperCarsData={bumperCarsData}
        introAudio={introAudio}
        tickingAudio={tickingAudio}
        pathname={props.location.pathname}
        carAudioRefs={carAudioRefs}
        setActiveBpm={setActiveBpm}
        matterCars={matterCars}
      />
    </div>
  );
}

const App = () => (
  <BrowserRouter basename="/">
    <Switch>
      <Route path="/" render={(props) => (
        <Content {...props} />
      )} />
    </Switch>
  </BrowserRouter>
);

export default App;
