import { useEffect, useRef } from 'react';
import { motion, useMotionValue } from 'framer-motion';
import SomersetLogo from '../../img/SomersetLogo';

const Information = (props) => {

  const { windowDimensions, informationData, pathname } = props;
  const { windowWidth, windowHeight } = windowDimensions;

  const wrapper = useRef();

  const x0 = useMotionValue(0);
  const y0 = useMotionValue(0);

  const x1 = useMotionValue(0);
  const y1 = useMotionValue(0);

  const x2 = useMotionValue(0);
  const y2 = useMotionValue(0);

  useEffect(() => {
    x0.set(0);
    y0.set(0);
    x1.set(0);
    y1.set(0);
    x2.set(0);
    y2.set(0);
  }, [windowWidth, windowHeight, x0, x1, x2, y0, y1, y2]);

  return (
    <motion.div
      initial={{ y: windowHeight }}
      animate={{ y: 0 }}
      exit={{ y: windowHeight }}
      transition={{
        type: 'tween',
        delay: pathname === '/information' ? 0.9 : 0
      }}
      className="view--information"
      style={{
        width: windowWidth + 'px',
        height: windowHeight + 'px'
      }}
    >
      <div className="view--information__background-wrapper">

      </div>
      <div className="view--information__inner">
        <div className="information-text__wrapper" ref={wrapper}>
          <motion.div
            dragConstraints={wrapper}
            style={{
              x: x0, y: y0
            }}
            drag={windowWidth >= 1200 ? 'y' : windowWidth >= 500 ? 'x' : null}
            className="information-text__module"
          >
            <div className="information-text__module__inner">
              <p>
                {informationData.about1}
              </p>
              <SomersetLogo fill="white" />
            </div>
          </motion.div>
          <motion.div
            dragConstraints={wrapper}
            style={{
              x: x1, y: y1
            }}
            drag={windowWidth >= 1200 ? 'y' : windowWidth >= 500 ? 'x' : null}
            className="information-text__module"
          >
            <div className="information-text__module__inner">
              <p>
                {informationData.about2}
              </p>
            </div>
          </motion.div>
          <motion.div
            dragConstraints={wrapper}
            style={{
              x: x2, y: y2
            }}
            drag={windowWidth >= 1200 ? 'y' : windowWidth >= 500 ? 'x' : null}
            className="information-text__module"
          >
            <div className="information-text__module__inner">
              <p>
                {informationData.about3}
              </p>
            </div>
          </motion.div>
        </div>
      </div>
    </motion.div>
  )
}

export default Information;