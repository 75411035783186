const playRandomCar = (matterCars) => {
  if (matterCars.current) {
    if (matterCars.current[0]) {
      const randomIndex = Math.floor(Math.random() * (matterCars.current.length - 0.01));
      const randomCar = matterCars.current[randomIndex];
      if (randomCar?.audioRef?.current) {
        randomCar.audioRef.current.currentTime = 0;
        randomCar.audioRef.current.play()
        .catch(error => console.error(error));
      }
    }
  }
}

export default playRandomCar;