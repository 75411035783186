import { Fragment } from "react";
import { cockpitUrl } from '../utils/cockpit';
import playRandomCar from '../utils/playRandomCar';

const BumperCarAudio = (props) => {

  const { audio, carAudioRef, bpm, setActiveBpm, matterCars, index } = props;

  const handlePlay = () => {
    setActiveBpm(bpm);
    if (matterCars.current) {
      for (let i = 0; i < matterCars.current.length; i++) {
        matterCars.current[i].playingCar = false;
        if (matterCars.current[index]) {
          matterCars.current[index].playingCar = true;
        }
      }
    }
  }

  const handleEnded = (e) => {
      setActiveBpm(-1);
      if (matterCars.current) {
        if (matterCars.current[index]) {
          matterCars.current[index].playingCar = false;
        }
      }
      playRandomCar(matterCars);
  }

  return (
    <audio ref={carAudioRef} src={cockpitUrl + '/' + audio} preload={`auto`} onPlay={handlePlay} onEnded={handleEnded} />
  )
}

const Audio = (props) => {

  const { introAudio, tickingAudio, introData, bumperCarsData, carAudioRefs, matterCars, setActiveBpm, pathname } = props;

  return (
    <Fragment>
    {
      introData.introAudio &&
      <audio
        ref={introAudio}
        src={cockpitUrl + '/' + introData.introAudio}
        onEnded={() => {
          playRandomCar(matterCars);
        }}
      />
    }
    {
      introData.tickingAudio && pathname === '/' &&
      <audio
        ref={tickingAudio}
        src={cockpitUrl + '/' + introData.tickingAudio}
        autoPlay
        loop
      />
    }
      {
        bumperCarsData.entries &&
        bumperCarsData.entries.map(
          (item, i) => (
            item.soundtrack &&
            <BumperCarAudio audio={item.soundtrack} key={i} index={i} carAudioRef={carAudioRefs[i]} matterCars={matterCars} bpm={item.bpm} setActiveBpm={setActiveBpm} />
          )
        )
      }
    </Fragment>
  )

}

export default Audio;