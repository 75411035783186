import ReactMarkdown from "react-markdown";
import { motion } from 'framer-motion';

const Win = (props) => {

  const { winData } = props;

  return (
    <motion.div
      initial={{ scale: 0, y: '-50%', x: '-50%' }}
      animate={{ scale: 1, y: '-50%', x: '-50%' }}
      exit={{ scale: 0, y: '-50%', x: '-50%' }}
      className="modal modal--win"
    >
      <ReactMarkdown>{winData.thanksMessage}</ReactMarkdown>
    </motion.div>
  )
}

export default Win;