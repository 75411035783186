import { useState, createRef, useEffect, useRef } from "react";
import classNames from "classnames";
import BumperCarsScene from "./BumperCarsScene";
import bodySilhouette from '../../img/texture/bumper-car/body--inverted.gif';
import features from '../../img/texture/bumper-car/features.gif';
import patterns from './BumperCarPatterns.js';
import Matter from '../utils/matter/src/module/main';

const createDataURLFromComponents = (bodyImg, featuresImg, patternImg) => {

  const canvas = document.createElement('canvas');
  const canvasRight = document.createElement('canvas');

  canvas.width = 248 / 2;
  canvas.height = 122 / 2;
  canvasRight.width = canvas.width;
  canvasRight.height = canvas.height;

  const context = canvas.getContext('2d');
  const contextRight = canvasRight.getContext('2d');

  context.imageSmoothingEnabled = false;
  context.mozImageSmoothingEnabled = false;
  context.webkitImageSmoothingEnabled = false;
  context.msImageSmoothingEnabled = false;
  contextRight.imageSmoothingEnabled = false;
  contextRight.mozImageSmoothingEnabled = false;
  contextRight.webkitImageSmoothingEnabled = false;
  contextRight.msImageSmoothingEnabled = false;

  if (patternImg) {
    context.drawImage(patternImg, 0, 0, canvas.width, canvas.height);
  } else {
    context.fillStyle = 'white';
    context.fillRect(0, 0, canvas.width, canvas.height);
  }
  context.globalCompositeOperation = 'destination-out';
  context.drawImage(bodyImg, 0, 0, canvas.width, canvas.height);
  context.globalCompositeOperation = 'source-over';
  context.drawImage(featuresImg, 0, 0, canvas.width, canvas.height);

  const src = canvas.toDataURL('image/gif');

  contextRight.translate(canvas.width, 0);
  contextRight.scale(-1, 1);
  contextRight.drawImage(canvas, 0, 0);

  const srcRight = canvasRight.toDataURL('image/gif');

  return {
    src: src,
    srcRight: srcRight,
    naturalWidth: canvas.width,
    naturalHeight: canvas.height
  }
}

const BumperCars = (props) => {

  const { windowDimensions, bumperCarsData, pathname, carAudioRefs, setSelectedCar, initialWindowMaxSide, introAudio, matterCars } = props;
  const [cars, setCars] = useState([]);
  const { windowWidth, windowHeight } = windowDimensions;

  const movingWall = useRef();
  const engine = useRef();

  const setMovingWall = (movingWallObject) => {
    movingWall.current = movingWallObject;
  }

  const setCarsArray = (carsArray) => {
    matterCars.current = carsArray;
  }

  const setEngine = (engineObject) => {
    engine.current = engineObject;
  }

  const [isDrivingOut, setIsDrivingOut] = useState(false);
  const [isDrivingIn, setIsDrivingIn] = useState(false);

  useEffect(() => {
    let isDrivingTimeout;

    setIsDrivingIn(false);
    setIsDrivingOut(true);
    if (matterCars.current) {
      for (let car of matterCars.current) {
        // Set the collision mask so the cars can drive through walls
        car.collisionFilter.category = 2;
        car.collisionFilter.mask = 0;
        car.render.sprite.texture = car.spriteLeftTexture;

        // Set the velocity to a random negative value so they all 'drive' offscreen at different speeds in the same direction
        Matter.Body.setVelocity(car, {
          x: Math.random() * -90,
          y: 0,
          scale: car.velocity.scale
        });
      }
    }

    isDrivingTimeout = setTimeout(() => {
      setIsDrivingIn(true);

      // 200ms for the cars to rearrange/resize themselves while the element is off screen.

      if (matterCars.current) {
        for (let car of matterCars.current) {
          const { displayWidth, displayHeight } = car;
          const newScale = Math.max(windowWidth, windowHeight) / initialWindowMaxSide;
          const newDisplayWidth = displayWidth * newScale;
          const newDisplayHeight = displayHeight * newScale;

          Matter.Body.scale(car, newScale, newScale);
          Matter.Body.set(car, { inertia: Infinity });
          car.render.sprite.xScale = car.render.sprite.yScale = car.spriteScale * newScale;

          let x = Math.floor(Math.random() * (windowWidth - newDisplayWidth / 2) + newDisplayWidth / 2);
          let y = Math.floor(Math.random() * ((windowHeight - 80) - newDisplayHeight / 2) + newDisplayHeight / 2) + 80;
          if (pathname === '/') {
            y = Math.floor(Math.random() * ((windowHeight - 80) / 5 - newDisplayHeight / 2) + newDisplayHeight / 2) + 80;
          }
          Matter.Body.setPosition(car, {
            x: x,
            y: y
          });
        }
      }

      isDrivingTimeout = setTimeout(() => {
        setIsDrivingOut(false);
        setIsDrivingIn(false);

        if (matterCars.current) {
          for (let car of matterCars.current) {
            car.collisionFilter.category = 2;
            car.collisionFilter.mask = 1;
            Matter.Body.setVelocity(car, {
              x: pathname !== '/play' ? pathname !== '/' ? 0 : Math.random() * 16 - 8 : Math.random() * 16 - 8,
              y: 0,
              scale: car.velocity.scale
            });
            car.render.sprite.texture = car.spriteLeftTexture;

            if (pathname === '/play' || pathname === '/') {
              Matter.Body.set(car, { friction: 0.01, frictionAir: 0.001 });
            } else {
              Matter.Body.set(car, { friction: 0.12, frictionAir: 0.02 });
            }
          }
        }
      }, 90);
    }, 900);

    return () => {
      clearTimeout(isDrivingTimeout);
    }
  }, [pathname, windowWidth, windowHeight, cars, initialWindowMaxSide, matterCars]);

  useEffect(() => {

    let bodyImg, featuresImg;
    const patternImgs = [];

    let loadedImageCount = 0;

    const onImgLoad = () => {
      loadedImageCount++;
      if (loadedImageCount === 2 + patterns.length) {

        const carsArray = [];
        // bumperCarsData.entries.length
        for (let i = 0; i < Math.min(patterns.length, bumperCarsData.entries.length); i++) {
          const car = bumperCarsData.entries[i];
          const carData = { ...car };
          carData.ref = createRef();
          carData.audioRef = carAudioRefs[i];
          carData.bpm = car.bpm;
          carData.pattern = patternImgs[i];
          carData.sprite = createDataURLFromComponents(bodyImg, featuresImg, patternImgs[i]);
          carData.activeImg = createDataURLFromComponents(bodyImg, featuresImg);

          carsArray.push(carData);
        }
        setCars([...carsArray]);
      }
    }

    bodyImg = document.createElement('img');
    bodyImg.addEventListener('load', onImgLoad);
    bodyImg.src = bodySilhouette;
    for (let pattern of patterns) {
      const patternImg = document.createElement('img');
      patternImg.addEventListener('load', onImgLoad);
      patternImg.src = pattern;
      patternImgs.push(patternImg);
    }
    featuresImg = document.createElement('img');
    featuresImg.addEventListener('load', onImgLoad);
    featuresImg.src = features;

  }, [bumperCarsData.entries, carAudioRefs]);

  const containerClass = classNames('bumper-cars',
    props.className,
    {
      'intro': pathname === '/',
      'win': pathname === '/win',
      'play': pathname === '/play',
      'drive--out': isDrivingOut === true,
      'drive--in': isDrivingIn === true
    }
  );

  return (
    <div className={containerClass}>
      <div className="bumper-cars__inner">
        {
          cars[0] &&
          <BumperCarsScene
            cars={cars}
            windowDimensions={windowDimensions}
            setMovingWall={setMovingWall}
            setEngine={setEngine}
            setCarsArray={setCarsArray}
            setSelectedCar={setSelectedCar}
            introAudio={introAudio}
            matterCars={matterCars}
          />
        }
      </div>
    </div>
  )
}

export default BumperCars;