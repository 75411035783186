import React from "react";
import { useState, useEffect, useRef } from 'react';
import Matter from '../utils/matter/src/module/main';
import play from '../../img/play.gif';
import createBumperCars from "./createBumperCars";

const Scene = (props) => {

  const scene = useRef();
  const { windowDimensions, cars, setMovingWall, setEngine, setCarsArray, setSelectedCar, introAudio } = props;
  const { windowWidth, windowHeight } = windowDimensions;
  const engine = useRef({});
  const [playButtonIsVisible, setPlayButtonIsVisible] = useState(false);
  const [trackToPlay, setTrackToPlay] = useState({});
  const render = useRef({});
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {

    const { Engine, Render, World, Bodies, Mouse, MouseConstraint, Events } = Matter;

    if (isInitialized === false) {
      setIsInitialized(true);

      engine.current = Engine.create();

      render.current = Render.create({
        element: scene.current,
        engine: engine.current,
        options: {
          background: 'transparent',
          width: windowWidth,
          height: windowHeight,
          wireframes: false
        }
      });

      const wallCategory = 0x0001,
            carCategory = 0x0002;

      World.add(engine.current.world, [
        // walls
        // top
        Bodies.rectangle(windowWidth / 2, -10 + 80, windowWidth, 20, {
          isStatic: true, mass: 9999999, render: { fillStyle: 'transparent' },
          collisionFilter: {
            category: wallCategory
          },
          label: 'topWall'
        }),
        // right
        Bodies.rectangle(windowWidth + 10, windowHeight / 2, 20, windowHeight, {
          isStatic: true, mass: 9999999, render: { fillStyle: 'transparent' },
          collisionFilter: {
            category: wallCategory
          },
          label: 'rightWall'
        }),
        // bottom
        Bodies.rectangle(windowWidth / 2, windowHeight + 10, windowWidth, 20, {
          isStatic: true, mass: 9999999, render: { fillStyle: 'transparent' },
          collisionFilter: {
            category: wallCategory
          },
          label: 'bottomWall'
        }),
        // left
        Bodies.rectangle(-10, windowHeight / 2, 20, windowHeight, {
          isStatic: true, mass: 9999999, render: { fillStyle: 'transparent' },
          collisionFilter: {
            category: wallCategory
          },
          label: 'leftWall'
        })
      ]);

      setMovingWall(engine.current.world.bodies[3]);
      setEngine(engine.current);

      // add mouse control
      const mouse = Mouse.create(render.canvas),
      mouseConstraint = MouseConstraint.create(engine.current, {
        mouse: mouse,
        constraint: {
          stiffness: 0.2,
          render: {
            visible: false
          }
        }
      });

      Mouse.mousewheel = null;

      World.add(engine.current.world, mouseConstraint);
      const carsArray = [];

      createBumperCars(cars, windowWidth, windowHeight, carsArray, carCategory, engine);

      const showPlayButton = (carObject) => {
        setPlayButtonIsVisible(true);
        setTrackToPlay(carObject.audioRef);
      }

      const playTrack = (carObject) => {

        for (let car of carsArray) {
          if (car.audioRef?.current) {
            car.audioRef.current.pause();
            car.playingCar = false;
          }
        }
        if (introAudio?.current?.paused === true) {
          if (carObject.audioRef) {
            carObject.audioRef.current.currentTime = 0;
            let startPlayPromise = carObject.audioRef.current.play();

            if (startPlayPromise !== undefined) {
              startPlayPromise.then(() => {
                // Start whatever you need to do only after playback
                // has begun.
              }).catch(error => {
                if (error.name === "NotAllowedError") {
                  if (window.location.href.indexOf('/play') > -1) {
                    showPlayButton(carObject);
                  }
                } else {
                  // Handle a load or playback error
                }
              });
            }
          }
        }
      }

      const randomiseVelocities = () => {
        for (let car of carsArray) {
          Matter.Body.setVelocity(car, {
            x: Math.random() * 6 - 3,
            y: 0
          });
          if (car.velocity.x > 0) {
            if (car.render.sprite.texture === car.spriteLeftTexture) {
              car.render.sprite.texture = car.spriteRightTexture;
            }
          } else if (car.velocity.x <= 0) {
            if (car.render.sprite.texture === car.spriteRightTexture) {
              car.render.sprite.texture = car.spriteLeftTexture;
            }
          }
        }
      }

      engine.current.world.gravity = {
        x: 0, y: 0
      }

      randomiseVelocities();

      setCarsArray(carsArray);

      const onDrag = (e) => {
        if (window.location.href.indexOf('/play') > -1) {
          for (let car of carsArray) {
            // If car is not the one which has been dragged, stop it from colliding with other inactive cars
            if (car.id !== e.body?.id) {
              car.collisionFilter.category = 2;
              car.collisionFilter.mask = 5;
              car.isActiveCar = false;
            }
          }
          // The car which has been dragged
          e.body.collisionFilter.category = 4;
          e.body.collisionFilter.mask = 7;
          e.body.isActiveCar = true;
        }
      }

      // const onStartDrag = (e) => {
      //   console.log(e)
      //   if (window.location.href.indexOf('/play') > -1) {
      //     if (/Android|webOS|iPhone|iPad|Mac|Macintosh|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      //       for (let car of carsArray) {
      //         if (car.id === e.body?.id) {
      //           // const handleTouchEnd = () => {
      //             playTrack(car);
      //           //   console.log('touchstart')
      //           //   window.removeEventListener('touchstart', handleTouchEnd);
      //           // }
      //           // window.addEventListener('touchstart', handleTouchEnd)
      //         }
      //       }
      //     }
      //   }
      // }

      Events.on(mouseConstraint, 'enddrag', onDrag);
      Engine.run(engine.current);

      Render.run(render.current);

      let count = 0;

      const animatePlayingCar = () => {
        count++;

        for (let car of carsArray) {
          if (car.playingCar === true) {
            if (car.velocity.x < 0) {
              if (count % 12 === 0 || car.render.sprite.texture === car.activeImgLeft || car.render.sprite.texture === car.spriteLeftTexture) {
                if (car.render.sprite.texture !== car.spriteRightTexture) {
                  car.render.sprite.texture = car.spriteRightTexture;
                } else {
                  car.render.sprite.texture = car.activeImgRight;
                }
              }
            } else if (car.velocity.x > 0) {
              if (count % 12 === 0 || car.render.sprite.texture === car.activeImgRight || car.render.sprite.texture === car.spriteRightTexture) {
                if (car.render.sprite.texture !== car.spriteLeftTexture) {
                  car.render.sprite.texture = car.spriteLeftTexture;
                } else {
                  car.render.sprite.texture = car.activeImgLeft;
                }
              }
            } else {
              if (count % 12 === 0) {
                if (car.render.sprite.texture === car.activeImgRight || car.render.sprite.texture === car.spriteRightTexture) {
                  if (car.render.sprite.texture !== car.spriteRightTexture) {
                    car.render.sprite.texture = car.spriteRightTexture;
                  } else {
                    car.render.sprite.texture = car.activeImgRight;
                  }
                } else {
                  if (car.render.sprite.texture !== car.spriteLeftTexture) {
                    car.render.sprite.texture = car.spriteLeftTexture;
                  } else {
                    car.render.sprite.texture = car.activeImgLeft;
                  }
                }
              }
            }
          } else {
            if (car.velocity.x > 0) {
              car.render.sprite.texture = car.spriteRightTexture;
            } else if (car.velocity.x < 0) {
              car.render.sprite.texture = car.spriteLeftTexture;
            } else {
              if (car.render.sprite.texture === car.activeImgRight || car.render.sprite.texture === car.spriteRightTexture) {
                car.render.sprite.texture = car.spriteRightTexture;
              } else {
                car.render.sprite.texture = car.spriteLeftTexture;
              }
            }
          }
        }
        requestAnimationFrame(animatePlayingCar);
      }

      requestAnimationFrame(animatePlayingCar);

      Events.on(engine.current, 'collisionEnd', function(event) {
          var pairs = event.pairs;

          for (let car of carsArray) {
            Matter.Body.setVelocity(car, {
              x: car.velocity.x,
              y: 0
            });
          }

          // change object colours to show those ending a collision
          for (var i = 0; i < pairs.length; i++) {
            var pair = pairs[i];

            if (pair.bodyA.isStatic === false && pair.bodyB.isStatic === false) {
              if (pair.bodyA.isActiveCar === true) {
                //play track and deactivate
                playTrack(pair.bodyA);
                pair.bodyA.collisionFilter.category = 2;
                pair.bodyA.collisionFilter.mask = 5;
                pair.bodyA.isActiveCar = false;
              }
              if (pair.bodyB.isActiveCar === true) {
                //play track and deactivate
                playTrack(pair.bodyB);
                pair.bodyB.collisionFilter.category = 2;
                pair.bodyB.collisionFilter.mask = 5;
                pair.bodyB.isActiveCar = false;
              }
            }
          }
      });

      const ctx = render.current.canvas.getContext('2d');
      ctx.imageSmoothingEnabled = false;
      ctx.mozImageSmoothingEnabled = false;
      ctx.webkitImageSmoothingEnabled = false;
      ctx.msImageSmoothingEnabled = false;
    }
  }, [windowWidth, windowHeight, cars, setMovingWall, isInitialized, setEngine, setCarsArray, setSelectedCar, introAudio]);

  return (
    <div className="gallery__wrapper">
      <div ref={scene} />
      {
        trackToPlay.current && playButtonIsVisible === true &&
        <button
          onClick={() => {
            setPlayButtonIsVisible(false);
            setTrackToPlay({});
            trackToPlay.current.play().catch(error => console.error);
          }}
          aria-label="play track"
          className="button--play-track button--large"
        >
          <div className="button--play-track__inner">
            <img
              className="button--play-track__img"
              src={play}
              alt="Play icon"
            />
          </div>
        </button>
      }
    </div>
  );
}
export default Scene;