import { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import play from '../../img/play.gif';
import skip from '../../img/skip.gif';
import playRandomCar from '../utils/playRandomCar';
import SomersetLogo from '../../img/SomersetLogo';

const Intro = (props) => {

  const { windowDimensions, setIntroIsComplete, introData, introAudio, setTickingIsActive, matterCars } = props;
  const { windowWidth, windowHeight } = windowDimensions;

  const { introText } = introData;
  const [activeIntroIndex, setActiveIntroIndex] = useState(0);

  const playDodgems = () => {
    if (introAudio.current) {
      introAudio.current.pause();
      introAudio.current.removeEventListener('ended', playDodgems);
    }
    props.history.push('/play');
  }

  useEffect(() => {
    setIntroIsComplete(false);
  }, [setIntroIsComplete]);

  return (
    <motion.div
      initial={{ x: -windowWidth }}
      animate={{ x: 0 }}
      exit={{ x: -windowWidth }}
      transition={{
        type: 'tween',
        duration: .4
      }}
      className="view--intro"
      style={{
        width: windowWidth + 'px',
        height: windowHeight + 'px',
        transformOrigin: 'top left'
      }}
    >
      <div className="view--intro__background-wrapper">

      </div>
      <div className="view--intro__inner">
        <div className="intro-text__wrapper dialogue">
          {
            introText &&
            <p>
              {introText[activeIntroIndex].value}
            </p>
          }
          {
            activeIntroIndex === 0 &&
            <SomersetLogo fill="white" />
          }
          <button
            className="intro__button--play intro__button button--large"
            onClick={() => {
              if (activeIntroIndex === introText.length - 1) {
                if (introAudio.current) {
                  setTickingIsActive(false);
                  setIntroIsComplete(true);
                  props.history.push('/play');
                  if (introAudio.current.paused === true) {
                      introAudio.current.play()
                      .then(() => { console.log('playing') })
                      .catch(error => console.error(error));
                  }
                  introAudio.current.addEventListener('ended', playDodgems);
                }
              } else {
                setActiveIntroIndex(activeIntroIndex + 1);
              }
            }}
            aria-label="next"
          >
            <img
              className="intro__button__img"
              src={play}
              alt=""
            />
          </button>
          <button
            className="intro__button--skip intro__button button--large"
            onClick={() => {
              setTickingIsActive(false);
              setIntroIsComplete(true);
              playDodgems();
              playRandomCar(matterCars);
            }}
            aria-label="next"
          >
            <img
              className="intro__button__img"
              src={skip}
              alt=""
            />
          </button>
        </div>
      </div>
    </motion.div>
  )
}

export default Intro;