import { useEffect } from 'react';
import { Link } from 'react-router-dom';

const Header = (props) => {

  const { windowDimensions, siteOptionsData, activeBpm, menuIsActive, setMenuIsActive, introAudio, carAudioRefs, setTickingIsActive } = props;
  const { windowWidth } = windowDimensions;

  useEffect(() => {
    const stopAllAudioAndCloseMenu = () => {
      setMenuIsActive(false);
      setTickingIsActive(false);
      if (carAudioRefs[0]) {
        for (let ref of carAudioRefs) {
          if (ref.current) {
            ref.current.pause();
            ref.current.currentTime = 0;
          }
        }
      }
      if (introAudio.current) {
        introAudio.current.pause();
      }
    }

    [...document.querySelectorAll('.menu__link')].map(element => element.addEventListener('click', stopAllAudioAndCloseMenu));

    return () => {
      [...document.querySelectorAll('.menu__link')].map(element => element.removeEventListener('click', stopAllAudioAndCloseMenu));
    }
  }, [introAudio, carAudioRefs, setMenuIsActive, setTickingIsActive]);

  return (
    <header className="header">
      {
        (windowWidth >= 768 || menuIsActive === false || windowWidth < 500) &&
        <Link className="menu__link heading--main__wrapper" to="/">
          <h1 className="heading--main">
            {
              (windowWidth >= 500 ? `Bumps Per Minute` : `BPM`).split('').map(
                (char, i) => (
                  <span className="heading--main__span" key={i}>{char}</span>
                )
              )
            }
            {
              activeBpm !== -1 &&
              <span className="heading--main__bpm"> {activeBpm}</span>
            }
          </h1>
        </Link>
      }
      {
        (windowWidth >= 768 || menuIsActive === true) &&
        <nav className="menu">
          <div className="menu__item">
            <Link className="menu__link menu__button link--play" to="/play">Play</Link>
          </div>
          {
            siteOptionsData.visit_url &&
            <div className="menu__item">
              <a className="menu__button link--visit" rel="noreferrer" target="_blank" href={siteOptionsData.visit_url}>Visit</a>
            </div>
          }
          <div className="menu__item">
            <Link className="menu__link menu__button link--win" to="/win">Win</Link>
          </div>
          {
            siteOptionsData.buy_url &&
            <div className="menu__item">
              <a className="menu__button link--buy" rel="noreferrer" target="_blank" href={siteOptionsData.buy_url}>Album</a>
            </div>
          }
          <div className="menu__item">
            <Link className="menu__link menu__button link--information" to="/information" title="Information">i</Link>
          </div>
        </nav>
      }
      {
        windowWidth < 768 &&
        <button
          className="button--large menu-button"
          onClick={(e) => { setMenuIsActive(!menuIsActive); }}
        >{menuIsActive === false ? 'Menu' : 'close'}</button>
      }
    </header>
  )
}

export default Header;