import Matter from '../utils/matter/src/core/Matter';

const { Bodies, World } = Matter;

const createBumperCars = (cars, windowWidth, windowHeight, carsArray, carCategory, engine) => {
  // destroy existing cars first
  if (carsArray[0]) {
    for (let existingCar of carsArray) {
      World.remove(engine.current.world, existingCar, true);
    }
  }
  if (cars[0]) {
    let i = 0;
    for (let car of cars) {
      const spriteScale = 1 / car.sprite.naturalWidth * Math.max(windowWidth, windowHeight) / 16;;
      const displayWidth = car.sprite.naturalWidth * spriteScale || 20;
      const displayHeight = car.sprite.naturalHeight * spriteScale || 20;

      const newBody = Bodies.rectangle(
        0,
        0,
        displayWidth,
        displayHeight,
        {
          name: `car${i}`,
          cockpitId: car._id,
          pattern: car.pattern,
          isActiveCar: false,
          audioRef: car.audioRef,
          mass: 10,
          restitution: 1,
          inertia: Infinity,
          inverseInertia: Infinity,
          friction: 0.12,
          frictionAir: 0.02,
          velocity: {
            x: Math.random() * 6 - 3,
            y: Math.random() * 6 - 3
          },
          render: {
            fillStyle: 'transparent',
            sprite: {
              texture: car.sprite.src,
              xScale: spriteScale,
              yScale: spriteScale,
              flip: true
            }
          },
          collisionFilter: {
            category: carCategory,
            mask: window.location.href.indexOf('/play') === -1 ? 1 : 7
          },
          spriteLeftTexture: car.sprite.src,
          spriteRightTexture: car.sprite.srcRight,
          activeImgLeft: car.activeImg.src,
          activeImgRight: car.activeImg.srcRight,
          displayWidth: displayWidth,
          displayHeight: displayHeight,
          spriteScale: spriteScale,
          bpm: car.bpm
        }
      );

      carsArray.push(newBody);

      World.add(
        engine.current.world,
        newBody
      );
      i++;
    }
  }

}

export default createBumperCars;