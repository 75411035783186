import pattern from '../../img/texture/bumper-car/patterns/pattern.gif';
import pattern2 from '../../img/texture/bumper-car/patterns/pattern2.gif';
import pattern3 from '../../img/texture/bumper-car/patterns/pattern3.gif';
import pattern4 from '../../img/texture/bumper-car/patterns/pattern4.gif';
import pattern5 from '../../img/texture/bumper-car/patterns/pattern5.gif';
import pattern6 from '../../img/texture/bumper-car/patterns/pattern6.gif';
import pattern7 from '../../img/texture/bumper-car/patterns/pattern7.gif';
import pattern8 from '../../img/texture/bumper-car/patterns/pattern8.gif';
import pattern9 from '../../img/texture/bumper-car/patterns/pattern9.gif';
import pattern10 from '../../img/texture/bumper-car/patterns/pattern10.gif';
import pattern11 from '../../img/texture/bumper-car/patterns/pattern11.gif';
import pattern12 from '../../img/texture/bumper-car/patterns/pattern12.gif';
import stripes from '../../img/texture/bumper-car/patterns/stripes.gif';
import stripes2 from '../../img/texture/bumper-car/patterns/stripes2.gif';
import stripes3 from '../../img/texture/bumper-car/patterns/stripes3.gif';
import stripesHorizontal from '../../img/texture/bumper-car/patterns/stripes--horizontal.gif';
import stripes2Horizontal from '../../img/texture/bumper-car/patterns/stripes2--horizontal.gif';
import stripes3Horizontal from '../../img/texture/bumper-car/patterns/stripes3--horizontal.gif';

const patterns = [
  pattern,
  pattern2,
  pattern3,
  pattern4,
  pattern5,
  pattern6,
  pattern7,
  pattern8,
  pattern9,
  pattern10,
  pattern11,
  pattern12,
  stripes,
  stripesHorizontal,
  stripes2,
  stripes2Horizontal,
  stripes3,
  stripes3Horizontal
];

export default patterns;