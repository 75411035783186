import { useState, useEffect } from "react";
import { motion } from 'framer-motion';
import ReactMarkdown from "react-markdown";
import skip from '../../img/skip.gif';
import playRandomCar from '../utils/playRandomCar';

const Play = (props) => {

  const { instructionsData, introAudio, matterCars, windowDimensions } = props;
  const { windowWidth } = windowDimensions;
  const [instructionsAreActive, setInstructionsAreActive] = useState(false);

  useEffect(() => {
    let timeout = setTimeout(() => {
      setInstructionsAreActive(true);
    }, 900);
    return () => {
      clearTimeout(timeout);
    }
  }, []);

    return (
      <motion.div
        initial={{ x: -windowWidth }}
        animate={{ x: 0 }}
        exit={{ x: -windowWidth }}
        transition={{
          type: 'tween',
          duration: .4
        }}
      >
        {
          instructionsAreActive === true &&
          <motion.div
            className="instructions__wrapper dialogue">
            <ReactMarkdown>
              {instructionsData.instructions}
            </ReactMarkdown>
            <button
              className="intro__button--play intro__button button--large"
              onClick={() => {
                if (introAudio.current) {
                  if (introAudio.current.paused === false) {
                    playRandomCar(matterCars);
                  }
                  introAudio.current.pause();
                  setInstructionsAreActive(false);
                }
              }}
              aria-label="close instructions and skip introduction audio"
            >
              <img
                className="intro__button__img"
                src={skip}
                alt="Skip icon"
              />
            </button>
          </motion.div>
        }
        <button
          className="instructions-button button--large"
          onClick={() => { setInstructionsAreActive(!instructionsAreActive) }}
          onTouchEnd={() => { setInstructionsAreActive(!instructionsAreActive) }}
        >?</button>
      </motion.div>
    );
}

export default Play;