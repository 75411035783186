import { useRef, useState, useEffect } from "react";
import backgroundImage from '../../img/texture/somerset-house-no-processing.jpg';
import Dither from 'canvas-dither';
import classNames from "classnames";

const Background = (props) => {

  const { windowDimensions, pathname } = props;
  const { windowWidth, windowHeight } = windowDimensions;
  const [imageIsLoaded, setImageIsLoaded] = useState(false);
  const canvas = useRef();
  const img = useRef();
  const dodge = useRef();

  const backgroundClass = classNames('background',
    props.className,
    {
      'play': pathname !== '/'
    }
  );

  useEffect(() => {
    const canvasWidth = windowWidth > 767 ? windowWidth * 0.25 : windowWidth * 0.5;

    const context = canvas?.current?.getContext('2d');

    const addGradientsInFrontAndBehind = () => {

      const backGradientCanvas = document.createElement('canvas');
      backGradientCanvas.width = canvasWidth;
      backGradientCanvas.height = windowHeight / windowWidth * canvasWidth;
      const backContext = backGradientCanvas.getContext('2d');
      const backGrad = backContext.createLinearGradient(0, 0, 0, backGradientCanvas.height);
      backGrad.addColorStop(0, 'white');
      backGrad.addColorStop(1, 'blue');

      // Fill with gradient
      backContext.fillStyle = backGrad;
      backContext.fillRect(0, 0, backGradientCanvas.width, backGradientCanvas.height);

      let backImageData = backContext.getImageData(0, 0, backGradientCanvas.width, backGradientCanvas.height);
      // Dither the data using the Atkinson algorithm
      backImageData = Dither.atkinson(backImageData);
      // Place the image data back on the canvas
      backContext.putImageData(backImageData, 0, 0);

      switchColors(
        { r: 255, g: 255, b: 255 },
        { r: 0, g: 255, b: 255 },
        backContext,
        backGradientCanvas.width,
        backGradientCanvas.height
      );

      const frontGradientCanvas = document.createElement('canvas');
      frontGradientCanvas.width = canvasWidth;
      frontGradientCanvas.height = (windowHeight * 1.3) / windowWidth * canvasWidth;
      const frontContext = frontGradientCanvas.getContext('2d');
      const frontGrad = frontContext.createLinearGradient(0, 0, 0, frontGradientCanvas.height);
      frontGrad.addColorStop(0, 'blue');
      frontGrad.addColorStop(1, 'white');

      // Fill with gradient
      frontContext.fillStyle = frontGrad;
      frontContext.fillRect(0, 0, frontGradientCanvas.width, frontGradientCanvas.height);

      let frontImageData = frontContext.getImageData(0, 0, frontGradientCanvas.width, frontGradientCanvas.height);
      // Dither the data using the Atkinson algorithm
      frontImageData = Dither.atkinson(frontImageData);
      // Place the image data back on the canvas
      frontContext.putImageData(frontImageData, 0, 0);

      switchColors(
        { r: 255, g: 255, b: 0 },
        { r: 0, g: 255, b: 255 },
        frontContext,
        frontGradientCanvas.width,
        frontGradientCanvas.height
      );

      frontImageData = frontContext.getImageData(0, 0, frontGradientCanvas.width, frontGradientCanvas.height);
      backImageData = backContext.getImageData(0, 0, backGradientCanvas.width, backGradientCanvas.height);

      const imageCanvas = document.createElement('canvas');
      imageCanvas.width = canvas.current.width;
      imageCanvas.height = canvas.current.height;
      imageCanvas.getContext('2d').drawImage(canvas.current, 0, 0);

      context.putImageData(backImageData, 0, 0);
      context.drawImage(imageCanvas, 0, 0);
      context.putImageData(frontImageData, 0, canvas.current.height - frontGradientCanvas.height);
    }

    const switchColors = (black, white, currentContext, w, h, transparentWhite) => {
      const imageData = currentContext.getImageData(0, 0, w, h);

      currentContext.imageSmoothingEnabled = false;
      currentContext.mozImageSmoothingEnabled = false;
      currentContext.webkitImageSmoothingEnabled = false;
      currentContext.msImageSmoothingEnabled = false;

      const { data } = imageData;

      for (let i = 0; i < data.length; i += 4) {
        if (data[i + 3] < 255) {
          data[i] = white.r;
          data[i + 1] = white.g;
          data[i + 2] = white.b;
          data[i + 3] = 0;
        } else if ((data[i] + data[i + 1] + data[i + 2]) / 3 < 255 * 0.8) {
          //black pixels
          data[i] = black.r;
          data[i + 1] = black.g;
          data[i + 2] = black.b;
        } else {
          //white pixels
          if (transparentWhite === true) {
            data[i + 3] = 0;
          }
          data[i] = white.r;
          data[i + 1] = white.g;
          data[i + 2] = white.b;
        }
      }
      currentContext.putImageData(imageData, 0, 0);
    }

    const addImageToCanvasAndDither = () => {
      if (canvas.current && img.current && imageIsLoaded === true) {
        const imgDrawingHeight = img.current.naturalHeight / img.current.naturalWidth * canvas.current.width;
        context.drawImage(img.current, 0, canvas.current.height * 0.35 - imgDrawingHeight, canvas.current.width, imgDrawingHeight);

        let image = context.getImageData(0, 0, canvas.current.width, canvas.current.height);
        // Dither the data using the Atkinson algorithm
        image = Dither.atkinson(image);
        // Place the image data back on the canvas
        context.putImageData(image, 0, 0);

        switchColors(
          { r: 255, g: 255, b: 0  },
          { r: 255, g: 255, b: 0 },
          context,
          canvas.current.width,
          canvas.current.height,
          true
        );
        addGradientsInFrontAndBehind();
      }
    }

    if (canvas.current && imageIsLoaded === true) {

      context.imageSmoothingEnabled = false;
      context.mozImageSmoothingEnabled = false;
      context.webkitImageSmoothingEnabled = false;
      context.msImageSmoothingEnabled = false;

      canvas.current.width = canvasWidth;
      // canvas.current.height = img.current.naturalHeight / img.current.naturalWidth * 480;
      canvas.current.height = windowHeight / windowWidth * canvasWidth * 2;

      addImageToCanvasAndDither();
    }
  }, [imageIsLoaded, windowWidth, windowHeight]);

  return (
    <div
      className={backgroundClass}
      style={{
        height: windowHeight * 2 + 'px',
        top: pathname !== '/' ? 0 - windowHeight + 'px' : 0
      }}
    >
      <img ref={img} src={backgroundImage} className="hidden" onLoad={() => setImageIsLoaded(true)} style={{ display: 'none' }} aria-hidden alt="background" />
      <canvas className="background__canvas" ref={canvas} />
      <h3
        className="heading--dodge"
        ref={dodge}
        style={{
          transform: dodge.current ?
          pathname === '/' ?
            `scale(${windowWidth / dodge.current.offsetWidth}, ${windowHeight / 8 / dodge.current.offsetHeight})` :
              `scale(${windowWidth / dodge.current.offsetWidth}, ${(windowHeight - 60) / dodge.current.offsetHeight})`
            : null
        }}
      >
        <span className="heading--dodge__span">D</span>
        <span className="heading--dodge__span">O</span>
        <span className="heading--dodge__span">D</span>
        <span className="heading--dodge__span">G</span>
        <span className="heading--dodge__span">E</span>
      </h3>
    </div>
  )
}

export default Background;